import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { StarFull, StarHalf, StarGrey } from "../Icons"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 6px;
`

const ReviewStars = ({ rating }) => {
  const stars = []
  const starsGrey = []
  const MAXIMUM_RATING = 5

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Math.floor(rating); i++) {
    stars.push(<StarFull key={i} />)
  }
  if (stars.length) {
    let greyStars;
    if (!Number.isInteger(rating)) {
      greyStars = MAXIMUM_RATING - (stars.length + 1)
    } else {
      greyStars = MAXIMUM_RATING - stars.length
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < greyStars; i++) {
      starsGrey.push(<StarGrey key={i} />)
    }
  }

  return (
    <Wrapper>
      {stars}
      {rating && !Number.isInteger(rating) && <StarHalf />}
      {starsGrey}
    </Wrapper>
  )
}
ReviewStars.propTypes = {
  rating: PropTypes.number.isRequired,
}
export default ReviewStars
