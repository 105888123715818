import React from "react"
import styled from "styled-components"
import Card from "../Card"
import Typography from "../Typography"
import { BlobIcon } from "../Icons"
import vSpacing from "../../styles/vSpacing"
import breakpoints from "../../styles/breakpoints"
import ChevronButton from "../ChevronButton"
import BlockText from "../BlockText"
import ReviewStars from "../ReviewStars"
import VideoReview from "./videoReview"
import slugify from "../../utils/slugify"

const { H300Semibold, H200 } = Typography

const Story = styled(Card)`
  padding: ${vSpacing.baseX2};
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: ${(props) => props.fullReview && "64px"};
  ${breakpoints.md`
    padding: ${vSpacing.baseX4};
    padding-bottom: ${(props) => props.fullReview && "64px"};
  `};
  transition: ${(props) => props.theme.transitions.hover};

  &:hover {
    cursor: ${(props) => !props.fullReview && `pointer`};
    box-shadow: ${(props) =>
      !props.fullReview && props.theme.boxShadow.zIndex5};
  }
`

const ReadMore = styled.div`
  position: absolute;
  bottom: 28px;
`

const StoryWrapper = styled.div`
  max-height: ${(props) =>
    props.isOpen ? "100%" : props.isVideoReview ? "500px" : "272px"};
  width: 100%;
  overflow: hidden;
`

const FadeStory = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(100, 100, 100, 0) 0%,
    rgba(255, 255, 255, 1) 75%
  );
  height: 60px;
  margin-top: -60px;
  position: relative;
`

const StoryHeader = styled.div`
  position: relative;
  .story-header-details-image {
    position: ${(props) => (props.isVideoReview ? "absolute" : "inherit")};
    bottom: ${(props) => (props.isVideoReview ? "8px" : "unset")};
    left: ${(props) => (props.isVideoReview ? "24px" : "unset")};
    display: block;
    align-items: center;
    margin-bottom: ${vSpacing.baseX2};
    ${breakpoints.md`
    display: flex;
  `};
  }
`

const StoryDetails = styled.div``

const StoryAuthor = styled.div`
  ${H300Semibold}
  color: ${(props) =>
    props.isVideoReview
      ? props.theme.colors.orange800
      : props.theme.colors.black900};
`

const StoryCountry = styled.div`
  ${H200}
  color: ${(props) =>
    props.isVideoReview
      ? props.theme.colors.orange600
      : props.theme.colors.black700};

  display: flex;
  align-items: center;
`

const StoryImage = styled.div`
  background-color: grey;
  background-image: url("${(props) => props.img}");
  background-position: center top;
  background-size: cover;
  margin-right: ${vSpacing.baseX2};
  border-radius: 50%;
  height: 82px;
  width: 82px;
`

class ReviewCard extends React.PureComponent {
  state = {
    open: false,
  }

  handleClick() {
    const { open } = this.state
    this.setState({
      open: !open,
    })
  }

  render() {
    const {
      author,
      content,
      country,
      date,
      avatar,
      btnText,
      fullReview,
      rating,
      videoReview,
    } = this.props
    const { open } = this.state
    const dateStory = new Date(date);
    const storyYear = dateStory.getFullYear();

    const isVideoReview = fullReview && !!videoReview
    return (
      <Story fullReview={fullReview}>
        <StoryWrapper isOpen={open} isVideoReview={isVideoReview}>
          <StoryHeader isVideoReview={isVideoReview}>
            {fullReview && videoReview && (
              <VideoReview videoReview={videoReview} />
            )}
            <div className="story-header-details-image">
              <StoryImage img={`${avatar}?w=82`} />
              <StoryDetails>
                <StoryAuthor isVideoReview={isVideoReview}>
                  {author}
                </StoryAuthor>
                <StoryCountry isVideoReview={isVideoReview}>
                  {country}
                  <BlobIcon
                    height="6"
                    width="6"
                    margin="0 8px 0 8px"
                    fill="#fec20d"
                  />
                  {storyYear}
                </StoryCountry>
                {rating && <ReviewStars rating={rating} />}
              </StoryDetails>
            </div>
          </StoryHeader>
          <BlockText blocks={content} />
          {fullReview && (
            <ReadMore>
              <ChevronButton
                onClickButton={() => this.handleClick()}
                active={open}
              >
                {btnText}
              </ChevronButton>
            </ReadMore>
          )}
        </StoryWrapper>
        {!open && <FadeStory />}
      </Story>
    )
  }
}

export default ReviewCard
