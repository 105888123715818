import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import localeData from "../../locale"
import AccordionItem from "../Accordion/AccordionItem"
import { LinkUnderline } from "../NormalLink"
import breakpoints from "../../styles/breakpoints"
import vSpacing from "../../styles/vSpacing"

import slugify from "../../utils/slugify"
import { currentLocale } from "../../locale/languages"

const Title4 = styled.h2`
  color: ${props => props.theme.primaryColors.primary};
  padding-bottom: ${vSpacing.baseX3};
  text-align: center;
  ${breakpoints.md`
    padding-bottom: ${vSpacing.baseX6};
    text-align: left;
  `};
`

const Faq = styled(Grid)`
  && {
    margin-bottom: 32px;
    ${breakpoints.md`
      margin-bottom: 56px;
  `};
  }
`;

const FaqCategory = ({ data, category, locale }) => {
  const faqs = category._rawFaqs
  const setLocaleInUrl = currentLocale !== locale ? `${locale}/` : ""

  return (
    <>
      <Faq container spacing={0} justify="center">
        <Grid item xs={12} md={8}>
          <Title4>{localeData._rawFaqTitle[locale]}</Title4>
          {faqs &&
            faqs.map(faqItem => (
              <AccordionItem
                key={faqItem._key}
                header={faqItem.question[locale]}
                body={faqItem.answer}
                locale={locale}
              />
            ))}
        </Grid>
      </Faq>
      <Grid container justify="center">
        <LinkUnderline
          to={`/${setLocaleInUrl}${slugify(
            data.sanityFaqPage._rawLocaleTitle[locale]
          )}`}
        >
          {localeData._rawFaqTitle[locale]}
        </LinkUnderline>
      </Grid>
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query faqCategory {
        sanityFaqPage {
          id
          _rawLocaleTitle
        }
      }
    `}
    render={data => <FaqCategory data={data} {...props} />}
  />
)
