import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Typography from "../Typography"
import vSpacing from "../../styles/vSpacing"
import { SideChevron } from "../Icons"
import breakpoints from "../../styles/breakpoints"
import ShowDesktopOnly from "../ShowDesktopOnly"
import { currentLocale } from "../../locale/languages"

const { H200Semibold, H200 } = Typography

const BreadCrumbContainer = styled.div`
  a,
  div {
    color: ${props => props.color};
  }
`

const Container = styled.div`
  margin-top: 83px;
  margin-left: ${vSpacing.baseX2};
  position: absolute;
  z-index: 10;
  display: flex;
  align-content: center;
  ${breakpoints.md`
    margin-top: ${vSpacing.baseX2};
    margin-left: 48px;
  `};
`

const BreadCrumbBold = styled(Link)`
  ${H200Semibold}
`
const BreadCrumb = styled.div`
  ${H200}
`

const BreadCrumbs = ({ pageTitle, parentTitle, path, color, locale }) => {
  const setLocaleInUrl = currentLocale !== locale ? `${locale}/` : ""

  return (
    <BreadCrumbContainer color={color}>
      <ShowDesktopOnly>
        <Container>
          <BreadCrumbBold to={`/${setLocaleInUrl}`}>Home</BreadCrumbBold>
          <SideChevron width="8px" height="8px" margin="0 8px 0 8px" />
          {parentTitle && (
            <>
              <Link to={path}>
                <BreadCrumb>{parentTitle}</BreadCrumb>
              </Link>
              <SideChevron width="8px" height="8px" margin="0 8px 0 8px" />
            </>
          )}
          <BreadCrumb>{pageTitle}</BreadCrumb>
        </Container>
      </ShowDesktopOnly>
    </BreadCrumbContainer>
  )
}

export default BreadCrumbs
