import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import YoutubeVideo from "../YoutubeVideo"
const VideoReviewWrapper = styled.div``

const VideoReview = ({ videoReview }) => {
  return (
    <VideoReviewWrapper>
      <YoutubeVideo url={videoReview} />
    </VideoReviewWrapper>
  )
}
VideoReview.propTypes = {
  children: PropTypes.string,
}
export default VideoReview
