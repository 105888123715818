import React, { Component } from "react"
import Grid from "@material-ui/core/Grid"
// import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'
import ReviewCard from "../ReviewCard"
import Vspacer from "../Vspacer"
import vSpacing from "../../styles/vSpacing"
import localeData from "../../locale"
import PrimaryBtn from "../Buttons/PrimaryBtn"
import slugify from "../../utils/slugify"

// configureAnchors({offset: -106, scrollDuration: 800})

class index extends Component {
  state = {
    limit: 4,
  }

  handleClick() {
    const { limit } = this.state
    this.setState({
      limit: limit + 4,
    })
  }

  render() {
    const { allSanityStories, locale } = this.props
    const { limit } = this.state
    return (
      <>
        <Vspacer marginBottom={vSpacing.baseX3}>
          <Grid container justify="center">
            <Grid item xs={12} md={8}>
              {allSanityStories.edges.slice(0, limit).map((story) => (
                <div id={`${slugify(story.node.name)}`} key={story.node.id}>
                  <Vspacer marginBottom={vSpacing.baseX4} key={story.node.id}>
                    <ReviewCard
                      author={story.node.name}
                      content={story.node._rawStory[locale]}
                      country={
                        story.node.vacancyCountry &&
                        story.node.vacancyCountry._rawLocaleTitle[locale]
                      }
                      date={story.node.year}
                      type={story.node.type}
                      rating={story.node.rating}
                      videoReview={story.node.videoReview}
                      avatar={story.node.avatar.asset.url}
                      btnText={localeData._rawReadMore[locale]}
                      fullReview
                    />
                  </Vspacer>
                </div>
              ))}
            </Grid>
          </Grid>
        </Vspacer>
        {limit < allSanityStories.edges.length && (
          <Grid container justify="center">
            <Grid item md={8} container justify="center">
              <PrimaryBtn onClick={() => this.handleClick()}>
                {localeData._rawReadMoreStories[locale]}
              </PrimaryBtn>
            </Grid>
          </Grid>
        )}
      </>
    )
  }
}

export default index
