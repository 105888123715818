import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Section from "../components/Section"
import { Container } from "../components/Container"
import HeroFullWidth from "../components/HeroFullWidth"
import SvgBorderTop from "../components/SvgBorderTop"
import AllSanityStories from "../components/AllSanityStories"
import FaqCategory from "../components/FaqCategory"
import BreadCrumbs from "../components/BreadCrumbs"

export const query = graphql`
  {
    sanityStoriesPage {
      id
      _rawLocaleTitle
      image {
        asset {
          gatsbyImageData(width: 1600, fit: FILLMAX, placeholder: BLURRED)
        }
      }
      faqCategory {
        _rawFaqs
      }
    }
    allSanityStories(sort: { fields: [year], order: DESC }) {
      edges {
        node {
          id
          name
          vacancyCountry {
            _rawLocaleTitle
          }
          _rawStory
          year
          videoReview
          rating
          avatar {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

const StoriesPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { _rawLocaleTitle, image, faqCategory } = data.sanityStoriesPage
  const { allSanityStories } = data

  return (
    <>
      <SEO title={_rawLocaleTitle[locale]} />
      {_rawLocaleTitle && (
        <BreadCrumbs
          color="white"
          pageTitle={_rawLocaleTitle[locale]}
          locale={locale}
        />
      )}
      {image && (
        <HeroFullWidth img={image} title={_rawLocaleTitle[locale]} grey />
      )}
      {allSanityStories && (
        <Container paddingTop grey paddingBottom>
          <Section>
            <AllSanityStories
              allSanityStories={allSanityStories}
              locale={locale}
            />
          </Section>
        </Container>
      )}
      {faqCategory && (
        <SvgBorderTop>
          <Container paddingTop paddingBottom>
            <Section>
              <FaqCategory category={faqCategory} locale={locale} />
            </Section>
          </Container>
        </SvgBorderTop>
      )}
    </>
  )
}

export default StoriesPage
