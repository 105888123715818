import React from 'react';
import styled from 'styled-components';
import { DownChevron } from '../Icons';

const Container = styled.div`
  display: flex;
  align-items: center;
  p {
    transition: ${props => props.theme.transitions.hover};
    &:hover {
      color: ${props => props.theme.colors.black400};
    }
  }
  &:hover {
    cursor: pointer;
  }
`;

const ChevronButton = ({ children, onClickButton, active }) => (
  <Container onClick={onClickButton}>
    <DownChevron width="16px" height="13px" margin="0 8px 0 0 " flip={active} />
    <p>{children}</p>
  </Container>
);

export default ChevronButton;
