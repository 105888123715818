import React from 'react';
import styled from 'styled-components';
import Collapse from '@material-ui/core/Collapse';
import Typography from '../../Typography';
import { DownChevron } from '../../Icons';
import breakpoints from '../../../styles/breakpoints';
import BlockText from "../../BlockText"

const { P } = Typography;

const Container = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.black200};
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  ${breakpoints.sm`
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;  
  `};
  &:last-child {
    border-bottom: none;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
  p {
    font-weight: ${props => props.active && '600'};
  }
  &:hover {
    cursor: pointer;
  }
  ${breakpoints.md`
    padding: 18px 0;    
  `};
`;

const Body = styled.div`
  padding: 0 0 14px 0;
  ${breakpoints.sm`
    padding: 0 0 14px 0;    
  `};
`;

class AccordionItem extends React.PureComponent {
  state = {
    open: false,
  };

  handleClick() {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  }

  render() {
    const { open } = this.state;
    const { header, body, locale } = this.props;

    return (
      <Container>
        <Header onClick={() => this.handleClick()} active={open}>
          <P>{header}</P>
          <DownChevron width="16px" height="13px" margin="0" flip={open} />
        </Header>
        <Collapse in={open}>
          <Body>
            <BlockText blocks={body[locale]} />
          </Body>
        </Collapse>
      </Container>
    );
  }
}

export default AccordionItem;
